import React, { FC } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { CalendlyBtn } from '../../../components/CalendlyBtn';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface HeroProps {
  data: {
    bookACall: string;
    description: string;
    seeCases: string;
    title: string;
    firstImage: {
      sourceUrl: string;
      altText: string;
      localFile: any;
    };
    secondImage: {
      sourceUrl: string;
      altText: string;
      localFile: any;
    };
    video: {
      mediaItemUrl: string;
      localFile: any;
    };
    videoPoster: {
      sourceUrl: string;
      altText: string;
      localFile: any;
    };
  };
}

const Hero: FC<HeroProps> = ({ data }) => {
  return (
    <Root>
      {data.video?.mediaItemUrl && (
        <RootVideo>
          <Video
            autoPlay
            muted
            loop
            playsInline
            poster={data.videoPoster.sourceUrl}
          >
            <source src={data.video.mediaItemUrl} type="video/mp4" />
          </Video>
        </RootVideo>
      )}
      <HeroContainer>
        <ContentWrapper>
          <ContentLeft>
            <Title dangerouslySetInnerHTML={{ __html: data.title }} />

            <MobileImageWrapper>
              {data.firstImage && (
                <StyledImage
                  localFile={data.firstImage.localFile}
                  alt={data.firstImage.altText}
                />
              )}
              {data.secondImage && (
                <StyledImage
                  localFile={data.secondImage.localFile}
                  alt={data.secondImage.altText}
                />
              )}
            </MobileImageWrapper>

            <ButtonsWrap>
              {data.bookACall && <StyledCalendlyBtn text={data.bookACall} />}
              {data.seeCases && (
                <ScrollLinkToCases to="cases" smooth={true}>
                  {data.seeCases}
                </ScrollLinkToCases>
              )}
            </ButtonsWrap>
          </ContentLeft>
          <ContentRight>
            <ContentDescription>{data.description}</ContentDescription>
            <DesktopImageWrapper>
              {data.firstImage && (
                <StyledImage
                  localFile={data.firstImage.localFile}
                  alt={data.firstImage.altText}
                />
              )}
              {data.secondImage && (
                <StyledImage
                  localFile={data.secondImage.localFile}
                  alt={data.secondImage.altText}
                />
              )}
            </DesktopImageWrapper>
          </ContentRight>
        </ContentWrapper>
      </HeroContainer>
    </Root>
  );
};

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RootVideo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

const Root = styled.section`
  position: relative;
  background-color: ${COLORS.lightGray};
`;

const HeroContainer = styled(Container)`
  position: relative;
  z-index: 2;
  padding-right: 9.97%;
  @media (max-width: 1439px) {
    padding-right: 7.5%;
  }
  @media (max-width: 1300px) {
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    padding-right: 32px;
  }

  @media (max-width: 768px) {
    padding-right: 16px;
    padding-bottom: 70px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ContentLeft = styled.div`
  max-width: 620px;
  padding-top: 199px;
  @media (max-width: 1200px) {
    padding-top: 135px;
    padding-bottom: 80px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    max-width: none;
    padding-top: 50px;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
`;

const Title = styled.div`
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle}
    color: ${COLORS.white};

    strong {
      color: ${COLORS.red};
    }
  }
  margin-bottom: 75px;

  @media (max-width: 768px) {
    margin-bottom: 65px;
    h1,
    h2,
    p {
      font-size: 48px;
      line-height: 55px;
    }
  }
  @media (max-width: 750px) {
    h1,
    h2,
    p {
      font-size: 36px;
      line-height: 41px;
    }
  }
`;

const StyledCalendlyBtn = styled(CalendlyBtn)`
  margin-right: 24px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    align-self: center;
    margin-bottom: 0px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    align-self: center;
    position: relative;
    /* &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      background-color: ${COLORS.darkGray};
    } */
  }
`;

const MobileImageWrapper = styled(ImageWrapper)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopImageWrapper = styled(ImageWrapper)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledImage = styled(Image)`
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  max-width: 213px;
  @media (max-width: 900px) {
    width: 158px;
    margin-right: 10px;
  }
`;

const ContentRight = styled.div`
  padding-left: 70px;
  padding-top: 197px;
  @media (max-width: 1439px) {
    padding-left: 40px;
  }
  @media (max-width: 1300px) {
    border: none;
  }
  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    padding-top: 95px;
    padding-left: 0;
    position: relative;
    /* &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      background-color: ${COLORS.darkGray};
    } */
  }
  @media (max-width: 768px) {
    padding-top: 0;
    &::before {
      content: none;
    }
  }
`;

const ContentDescription = styled.p`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  max-width: 445px;
  margin-bottom: 60px;
  @media (max-width: 1024px) {
    max-width: 290px;
  }
  @media (max-width: 768px) {
    max-width: none;
    margin-bottom: 0;
  }
`;

const ScrollLinkToCases = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  padding: 19px;
  min-width: 250px;
  cursor: pointer;
  color: ${COLORS.white};
  border-radius: 5px;
  border: 1px solid ${COLORS.white};
  background-color: transparent;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default Hero;
