import React, { FC } from 'react';
import styled from 'styled-components';
import CircleLines from '../../assets/images/svg/CircleLines';
import { Container } from '../Container';
import { Image } from '../Image';
import { COLORS, TYPOGRAPHY } from '../../constants';

interface NewFeatureProps {
  data: {
    titleFeature: string;
    hashtag: string;
    descriptionFeature: string;
    image: {
      localFile: any;
    };
  };
}

const NewFeature: FC<NewFeatureProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <Content>
          <Hashtag>{data.hashtag}</Hashtag>
          <Title>{data.titleFeature}</Title>
          <DescriptionWrap
            dangerouslySetInnerHTML={{
              __html: data.descriptionFeature,
            }}
          />
        </Content>

        <PhoneWrap>
          <StyledCircleLines
            width={574}
            height={574}
            customStrokeWidth={5}
            customOpacity={0.75}
          />
          <StyledImage
            localFile={data.image.localFile}
            alt={data.titleFeature}
          />
        </PhoneWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.black2};
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: 70px;
  padding-bottom: 80px;
  position: relative;
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 500px;
  }
  @media (max-width: 700px) {
    padding-bottom: 350px;
  }
`;

const Content = styled.div`
  max-width: 740px;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    max-width: none;
  }
`;

const Hashtag = styled.p`
  font-size: 24px;
  line-height: 36px;

  color: ${COLORS.white};
  margin-bottom: 40px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};
  margin-bottom: 41px;
  @media (max-width: 1400px) {
    max-width: 540px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
    max-width: none;
  }
`;

const DescriptionWrap = styled.div`
  max-width: 700px;
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  p {
    margin-bottom: 35px;
  }
  ul {
    padding-left: 15px;
  }
  li {
    margin-bottom: 10px;
    list-style: disc;
  }

  p,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 1400px) {
    max-width: 600px;
  }
  @media (max-width: 400px) {
    max-width: 315px;
  }
`;

const PhoneWrap = styled.div`
  position: absolute;
  top: 110px;
  right: 10.4%;

  &::before {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    background-color: ${COLORS.red};
    border-radius: 50%;
    filter: blur(110px);
    top: 17.9%;
    left: -25%;
  }
  @media (max-width: 1300px) {
    right: 50px;
  }
  @media (max-width: 1024px) {
    right: 0;
    top: auto;
    left: 50%;
    bottom: -299px;
    transform: translateX(-50%);
    width: fit-content;
  }
  @media (max-width: 700px) {
    bottom: -260px;
  }
`;

const StyledImage = styled(Image)`
  max-width: 341px;
  position: relative;
  @media (max-width: 700px) {
    width: 270px;
  }
`;

const StyledCircleLines = styled(CircleLines)`
  position: absolute;
  top: -21%;
  left: -45px;
  @media (max-width: 1024px) {
    left: -8px;
    top: -33%;
  }
  @media (max-width: 700px) {
    width: 410px;
    height: auto;
    left: 27px;
    top: -32%;
  }
`;

export default NewFeature;
