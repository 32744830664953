import { Link } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import Arrow from '../../assets/images/svg/Arrow';
import { COLORS } from '../../constants';

interface UiLinkProps {
  text: string;
  className?: string;
  hasArrow?: boolean;
  isTransparent?: boolean;
  href?: string;
  isButton?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}

interface RootProps {
  $hasArrow: boolean;
  $isTransparent: boolean;
}

const UiLink: FC<UiLinkProps> = ({
  text,
  hasArrow = true,
  isTransparent = false,
  href = '/',
  className,
  onClick,
  isButton = false,
}) => {
  return isButton ? (
    <Root
      className={className}
      $hasArrow={hasArrow}
      $isTransparent={isTransparent}
      as="button"
      onClick={onClick}
    >
      <span>
        {text}
        {hasArrow && <StyledArrow />}
      </span>
    </Root>
  ) : (
    <Root
      className={className}
      $hasArrow={hasArrow}
      $isTransparent={isTransparent}
      to={href}
    >
      <span>
        {text}
        {hasArrow && <StyledArrow />}
      </span>
    </Root>
  );
};

const Root = styled(Link)<RootProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  padding: 19px;
  min-width: 250px;
  cursor: pointer;
  color: ${({ $isTransparent }) =>
    $isTransparent ? COLORS.black2 : COLORS.white};
  border-radius: 5px;
  border: 1px solid
    ${({ $isTransparent }) => ($isTransparent ? COLORS.black3 : COLORS.red)};
  background-color: ${({ $isTransparent }) =>
    $isTransparent ? 'transparent' : COLORS.red};
  span {
    position: relative;
    display: block;
    padding-right: ${({ $hasArrow }) => ($hasArrow ? '63px' : '0')};
  }
  @media (max-width: 500px) {
    span {
      transform: ${({ $hasArrow }) =>
        $hasArrow ? 'translateX(32px)' : 'none'};
    }
  }
`;

const StyledArrow = styled(Arrow)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export default UiLink;
