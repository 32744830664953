import * as React from 'react';
import { SVGProps } from 'react';
import { getRandomArbitrary } from '../../../helpers/getRandomArbitrary';

interface CircleLinesProps extends SVGProps<SVGSVGElement> {
  className?: string;
  customStrokeWidth?: number;
  customOpacity?: number;
}

const CircleLines = ({
  className,
  customStrokeWidth,
  customOpacity,
  ...props
}: CircleLinesProps) => {
  const maskId = `mask-id-${getRandomArbitrary(1, 100)}`;
  const gradientId = `gradient-id-${getRandomArbitrary(1, 100)}`;
  return (
    <svg
      width={490}
      height={490}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      className={className}
      {...props}
    >
      <mask
        id={maskId}
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={490}
        height={490}
      >
        <circle cx={245} cy={245} r={245} fill={`url(#${gradientId})`} />
      </mask>
      <g
        mask={`url(#${maskId})`}
        stroke="#FF1E44"
        strokeWidth={customStrokeWidth ? customStrokeWidth : 2}
      >
        <circle cx={244.302} cy={244.302} r={303.331} />
        <circle cx={244.302} cy={244.302} r={279.791} />
        <circle cx={244.303} cy={244.302} r={256.252} />
        <circle cx={244.302} cy={244.302} r={232.712} />
        <circle cx={244.303} cy={244.302} r={209.173} />
        <circle cx={244.302} cy={244.302} r={185.634} />
        <circle cx={244.302} cy={244.302} r={162.094} />
        <circle cx={244.302} cy={244.302} r={138.555} />
        <circle cx={244.302} cy={244.302} r={118.378} />
        <circle cx={244.302} cy={244.302} r={98.202} />
        <circle cx={244.302} cy={244.302} r={78.025} />
        <circle cx={244.301} cy={244.302} r={61.211} />
        <circle cx={244.301} cy={244.302} r={41.035} />
        <circle cx={244.302} cy={244.302} r={24.221} />
      </g>
      <defs>
        <radialGradient
          id={gradientId}
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 0 245) scale(245)"
        >
          <stop stopOpacity={customOpacity ? customOpacity : 0.25} />
          <stop offset={1} stopColor="#F2F2F2" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default CircleLines;
