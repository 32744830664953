import React, { FC } from 'react';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';
import { CalendlyBtn } from '../CalendlyBtn';
import { Container } from '../Container';
import { Image } from '../Image';

interface ReleaseNowProps {
  data: {
    buttonText: string;
    description: string;
    title: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

const ReleaseNow: FC<ReleaseNowProps> = ({ data }) => {
  const hasImage = data.image ? true : false;

  return (
    <Root>
      <StyledContainer $withImage={hasImage}>
        <ContentWrap $withImage={hasImage}>
          <Title>{data.title}</Title>
          <DescriptionWrap
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          <StyledCalendly text={data.buttonText} $withImage={hasImage} />
        </ContentWrap>
        {hasImage && (
          <ImageWrap>
            <StyledImage localFile={data.image.localFile} alt={data.title} />
          </ImageWrap>
        )}
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)<{ $withImage: boolean }>`
  display: flex;
  justify-content: ${({ $withImage }) =>
    $withImage ? 'space-between' : 'center'};
  padding-top: 117px;
  padding-bottom: 132px;

  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 105px;
  }
  @media (max-width: 600px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }
`;

const ContentWrap = styled.div<{ $withImage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $withImage }) => ($withImage ? 'flex-start' : 'center')};
  @media (max-width: 1200px) {
    align-items: center;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
    width: 100%;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.black3};
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 41px;
  }
`;

const DescriptionWrap = styled.div`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.black3};
  margin-bottom: 56px;
  p {
    margin-bottom: 30px;
    max-width: 630px;
    text-align: center;
  }
  li {
    margin-bottom: 6px;
    padding-left: 21px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 11px;
      height: 8px;
      background: url('/images/check.svg') no-repeat center;
      background-size: cover;
    }
  }

  p,
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 40px;
    p {
      text-align: left;
    }
  }
  @media (max-width: 400px) {
    max-width: 315px;
  }
`;

const StyledCalendly = styled(CalendlyBtn)<{ $withImage: boolean }>`
  align-self: ${({ $withImage }) => ($withImage ? 'flex-start' : 'center')};
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 1200px) {
    align-self: center;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ImageWrap = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const StyledImage = styled(Image)`
  max-width: 451px;
`;

export default ReleaseNow;
