import * as React from 'react';

interface ArrowProps {
  color?: string;
  className?: string;
}

function Arrow(props: React.SVGProps<SVGSVGElement> & ArrowProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="17"
      fill="none"
      viewBox="0 0 46 17"
      className={props.className}
    >
      <path
        stroke={props.color ? props.color : '#fff'}
        d="M38 16l7-7.5L38 1"
      ></path>
      <path
        stroke={props.color ? props.color : '#fff'}
        d="M0 8.5L45 8.5"
      ></path>
    </svg>
  );
}

export default Arrow;
