import React, { FC } from 'react';
import { openPopupWidget } from 'react-calendly';

import { UiLink } from '../../ui/UiLink';

interface CalendlyBtnProps {
  text: string;
  className?: string;
}

const CalendlyBtn: FC<CalendlyBtnProps> = ({ text, className }) => {
  const handleBtnClick = () =>
    openPopupWidget({
      url:
        'https://calendly.com/jan-bal/intro-brands-are-live?hide_gdpr_banner=1',
      pageSettings: { primaryColor: 'ff1e44' },
    });

  return (
    <UiLink
      className={className}
      text={text}
      isButton={true}
      onClick={handleBtnClick}
    />
  );
};

export default CalendlyBtn;
